const rtbChannels = [11, 14, 15, 16, 17, 18]

export default {
  methods: {
    filterSharedChannelBudgets (budgets, advertisingChannelId) {
      const validChannels = rtbChannels.includes(advertisingChannelId) ? rtbChannels : [advertisingChannelId]
      return budgets
        .filter(b => b.budgetPlanId && validChannels.includes(b.advertisingChannelId) && b.explicitlyShared)
    },
    validateCampaignGroup (budget, campaigns) {
      const validated = { valid: [], invalid: [] }
      for (const campaign of campaigns) {
        if (budget?.campaignGroupName && campaign?.campaignGroupName && budget.campaignGroupName !== campaign.campaignGroupName) {
          validated.invalid.push(campaign)
        } else {
          validated.valid.push(campaign)
        }
      }
      return validated
    }
  }
}
